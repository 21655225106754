/* eslint-disable */
import AWS from "aws-sdk";

const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
});

export const UploadFileToS3 = async (Key, Body, setProgress) => {
    const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key,
        Body,
        ACL: "public-read",
    };
    try {
        const response = await s3.upload(params, { queueSize: 1 }).on("httpUploadProgress", (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
        }).promise();
        return response;
    } catch (error) {
        console.log("uploading error", error);
    }
}
