import React from 'react';

const CustomAlertModal = ({ message, onClose }) => {
  return (
    <div className="custom-modal">
      <div className="custom-modal-content">
        {Array.isArray(message) ? (
          <p className="mb-2">
            {message[0]?.fileSizeTooSmall && 'File size is too small!'}
            {message[0]?.fileSizeToolarge && 'File size is too large!'}
            {message[0]?.readerError && 'Problem occured while reading file!'}
            {message[0]?.minLimitNotReached && 'Not enough files'}
          </p>
        ) : (
          <p className="mb-2">{message}</p>
        )}

        <button className="custom-modal-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default CustomAlertModal;
