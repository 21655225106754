import React, { useState } from 'react';

function ConfirmationModal({ message, onConfirm, onCancel }) {
  const [isOpen, setIsOpen] = useState(true);

  const handleConfirm = () => {
    onConfirm();
    setIsOpen(false);
  };

  const handleCancel = () => {
    onCancel();
    setIsOpen(false);
  };

  return isOpen ? (
    <div className="custom-modal">
      <div className="custom-modal-content">
        <p className="mb-2">{message}</p>
        <button className="custom-modal-btn" onClick={handleConfirm}>
          Confirm
        </button>
        <button className="custom-modal-btn mr-2" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  ) : null;
}

export default ConfirmationModal;
