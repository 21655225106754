/*eslint-disable*/
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../assets/styles/global.scss';
import '../../App.scss';
import SubHeader from '../SubHeader';
import backArrow from '../../assets/icons/backArrow.svg';
import icon from '../../assets/icons/blogIcon.svg';
import EmailDropDown from '../EmailDropDown';
import CommonHeader from '../CommonHeader';
import { getEntityId } from '../../helpers/date-conversions';
import { ReactComponent as Logo } from '../../assets/icons/invocom-icon-chat.svg';

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = localStorage.getItem(`${getEntityId()}:userEmail`);

  const { route, setUserChatId, setIsNewMessage, setRedirect, setSelectedAnswers } = props;
  function handleBackClick() {
    const previousRoute = location?.state?.from;
    if (route === 'form') {
      setSelectedAnswers([]);
      if (previousRoute === 'main') {
        navigate('/');
      } else {
        navigate(-2);
      }
    } else if (route === 'calendar') {
      navigate(-2);
    } else if(route === 'email'){
      navigate('/chat',  { state: { from: 'email-transcript' } })
    } else if (route === 'blog') {
      navigate('/');
    } else if(route === 'blog-detail') {
      if(previousRoute === 'blogs'){
        navigate('/blog')
      }else{
        navigate('/')
      }
    } else if (route === 'chat') {
      setSelectedAnswers([]);
      if (previousRoute === 'calendar' || previousRoute === 'messages') {
        navigate(-1);
      } else {
        navigate('/');
      }
    } else {
      navigate(-1);
    }
  }

  return (
    <>
      {route === 'main' ? (
        <SubHeader setUserChatId={setUserChatId} setIsNewMessage={setIsNewMessage} setRedirect={setRedirect} />
      ) : route === 'chat' ? (
        <div className="chatbot_header w-lg text-white px-5">
          <EmailDropDown />
          <div className="d-flex items-center gap-3">
            <img src={backArrow} alt="icon" onClick={handleBackClick} className="cursor-pointer" />
            <div className="d-flex items-center gap-3">
              <Logo />
              <div className="d-flex items-center gap-1">
                <h5 className="name">{localStorage.getItem(`${getEntityId()}:fullname`)}</h5>
              </div>
            </div>
          </div>
        </div>
      ) : route === 'form' ? (
        <div className="headerSmall w-lg text-white px-5 d-flex">
          <div className="d-flex items-center justify-between w-100">
            <div className="d-flex gap-3">
              <img className="cursor-pointer" src={backArrow} onClick={handleBackClick} alt="icon" />
              <div>
                <p className="title">Connect with us</p>
                <span className="sub-title">{JSON.parse(localStorage.getItem(`${getEntityId()}:headerStatement`))} </span>
              </div>
            </div>
          </div>
        </div>
      ) : route === 'blog' ? (
        <div className="blogs_header px-5">
          <div className="d-flex gap-4">
            <img className="cursor-pointer" src={backArrow} onClick={handleBackClick} alt="icon" />
            <div className="top">
              <img src={icon} alt="icon" />
              <h3>Help</h3>
            </div>
          </div>
        </div>
      ) : route === 'blog-detail' || route === 'email' || route === 'messages' || route === 'calendar' ? (
        <CommonHeader handleBackClick={() => handleBackClick()} route={route} />
      ) : (
        ''
      )}
    </>
  );
};

export default Header;
