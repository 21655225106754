/* eslint-disable */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/global.scss';
import '../../App.scss';
import { getEntityId } from '../../helpers/date-conversions';
import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as Chat } from '../../assets/icons/chat.svg';
import { ReactComponent as Blogs } from '../../assets/icons/blogs.svg';

const BottomFooter = ({ active }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/form')
  }
  return (
    <>
      <div className="bottomFooter px-5">
        <div
          className={`nav_link ${active === 'main' ? 'navActive' : ''} d-flex items-center justify-center gap-1.5`}
          onClick={() => navigate('/')}
        >
          <Home className="svgWhite" />
          <span>Home</span>
        </div>
        <div
          className={`nav_link ${active === 'messages' ? 'navActive' : ''} d-flex items-center justify-center gap-1.5`}
          onClick={() => navigate('/messages')}
        >
          <Chat className="svgWhite" />
          <span>Message</span>
        </div>
        <div
          className={`nav_link ${active === 'blogs' ? 'navActive' : ''} d-flex items-center justify-center gap-1.5`}
          onClick={() => navigate('/blog')}
        >
          <Blogs className="svgWhite" />
          <span>Help</span>
        </div>
      </div>
    </>
  );
};

export default BottomFooter;
