/* eslint-disable */
import React, { useEffect, useState } from 'react';
import '../../assets/styles/global.scss';
import '../../App.scss';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import loadingGif from '../../assets/icons/loading.gif';
import { getEntityId, convertIntoHumanReadableTime } from '../../helpers/date-conversions';
import { fetchEntityChats, renderChat } from '../../shared/api';
import { ReactComponent as NextArrow } from '../../assets/icons/next-arrow.svg';
import { ReactComponent as Logo } from '../../assets/icons/invocom-icon-chat.svg';
import BottomFooter from '../../Components/BottomFooter';
import Tooltip from '../../Components/Tooltip';
const sound = './Email.mp3';

const Messages = ({ setIsNewMessage, setUserChatId }) => {
  const [loading, setLoading] = useState(false);
  const [entityChats, setEntityChats] = useState([]);
  const mainHeading = JSON.parse(localStorage.getItem(`${getEntityId()}:mainHeading`));
  const [userId, ] = useState(localStorage.getItem(`${getEntityId()}:id`));
  const navigate = useNavigate();

  useEffect(async () => {
    if (localStorage.getItem(`${getEntityId()}:userEmail`)) {
      const chats = await fetchEntityChats();
      setEntityChats(chats);
    } else {
      navigate('/form', { state: { from: 'messages' } });
    }
    return () => {
      setEntityChats([]);
    };
  }, [localStorage.getItem(`${getEntityId()}:userEmail`)]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_INVOCOM_API_URL, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
      transports: ['websocket'],
      upgrade: false,
    });
    const urlAudio = new Audio();

    const id = localStorage.getItem(`${getEntityId()}:id`);
    const entityRecord = localStorage.getItem(`${getEntityId()}:entityId`);
    socket.on(`sendNewMessageToClient-${userId}`, async (updatedChat) => {
      urlAudio.src = sound;
      urlAudio.autoplay = true;
      urlAudio.type = 'audio/mp3';
      setTimeout(function () {
        urlAudio.play();
      }, 100);
      if (updatedChat?.client?._id === id) {
        const updatedChatResponse = await fetchEntityChats();
        setEntityChats(updatedChatResponse);
      }
      urlAudio.pause();
    });
    return () => {
      setEntityChats([]);
    };
  }, []);

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
          <img src={loadingGif} alt="loading" style={{ width: '25px' }} />
        </div>
      ) : entityChats.length ? (
        <>
          <div className="messages-body" style={{height: "485px"}}>
            <ul className="lists">
              {entityChats?.map((chat) => {
                const time = convertIntoHumanReadableTime(
                  chat.messages.length > 0 ? chat.messages[chat.messages.length - 1]?.updatedAt : chat.createdAt
                );
                return (
                  <li
                    key={chat._id}
                    className="list"
                    onClick={() => {
                      setIsNewMessage(false);
                      setUserChatId(chat._id);
                      renderChat(chat._id);
                      localStorage.setItem(`${getEntityId()}:questions`, true);
                      navigate('/chat', { state: { from: 'messages' } });
                    }}
                  >
                    <div className="d-flex message-box">
                      <div className="image">
                        <div className="">
                          <Logo />
                        </div>
                      </div>
                      <div className="text">
                        <div className="column">
                          <div className="d-flex justify-content-between">
                            <div className="admin-text">{mainHeading}</div>
                            {chat.clientUnReadMessages !== 0 ? (
                              <span className="unReadMessage">{chat.clientUnReadMessages}</span>
                            ) : (
                              ''
                            )}
                            <div>
                              <Tooltip text="Go on Chat" position="left">
                                <NextArrow />
                              </Tooltip>
                            </div>
                          </div>
                          <div className="admin">
                            <div className="text-wrap">
                              <span className="white-space">
                                {chat.messages.length > 0
                                  ? chat.messages[chat.messages.length - 1]?.message
                                    ? chat.messages[chat.messages.length - 1]?.message
                                    : chat.messages[chat.messages.length - 1]?.audio
                                    ? 'Audio'
                                    : chat.messages[chat.messages.length - 1]?.image
                                    ? 'Image'
                                    : 'No New Message'
                                  : 'No New Message'}
                              </span>
                            </div>
                            <div className="date">{time ? ` • ${time}` : ''}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      ) : (
        ''
      )}
      <BottomFooter active="messages" />
    </>
  );
};

export default Messages;
