import { getEntityId } from './helpers/date-conversions';

export default function setColorProperties() {
  if (localStorage.getItem(`${getEntityId()}:primaryColor`)) {
    const root = document.documentElement;
    root.style.setProperty('--primary-color', JSON.parse(localStorage.getItem(`${getEntityId()}:primaryColor`)));
    root.style.setProperty('--secondary-color', JSON.parse(localStorage.getItem(`${getEntityId()}:secondaryColor`)));
    root.style.setProperty('--gradiant-color', JSON.parse(localStorage.getItem(`${getEntityId()}:gradientColor`)));
    root.style.setProperty('--background-color', JSON.parse(localStorage.getItem(`${getEntityId()}:backgroundColor`)));
  }
}
