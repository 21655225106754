/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './assets/styles/global.scss';
import './App.scss';
import changeStatus from './service/user';
import EmailTranscript from './screens/EmailTranscriptScreen';
import Blog from './screens/Blogs';
import CalendarScreen from './screens/CalendarScreen';
import ChatScreen from './screens/ChatScreen';
import HomeScreen from './screens/HomeScreen';
import MessageScreen from './screens/MessageScreen';
import loadingGif from './assets/icons/loading.gif';
import Header from './Components/Header';
import setColorProperties from './registerColorProperties';
import SplashScreen from './screens/splashScreen';
import { getEntityId, setEntityId, setUserEmail, getUserEmail } from './helpers/date-conversions';
import Form from './screens/Form';
import BlogDetail from './Components/BlogDetail';

const url = window.document.referrer ? new URL(window.document.referrer).origin : window.location.url;

const App = () => {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const [entityIdLocal, setEntityIdLocal] = useState(null);
  const [, setLoader] = useState(true);
  const [userChatId, setUserChatId] = useState(null);

  const renderLoader = () => <img src={loadingGif} alt="loading" style={{ width: '25px' }} />;

  const getChatStatus = async () => {
    try {
      setLoader(true);
      const baseUrl = process.env.REACT_APP_INVOCOM_API_URL;
      const apiVersion = process.env.REACT_APP_INVOCOM_API_VERSION;
      const entity = 'chat';
      const response = await fetch(`${baseUrl}/${apiVersion}/${entity}/status`, {
        method: 'POST',
        body: JSON.stringify({
          url,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setEntityIdLocal(data?.entity?._id)
      setEntityId(data.entity._id);
      
      setUserEmail(localStorage.getItem(`${data.entity._id}:userEmail`) || null);
      localStorage.setItem(`${getEntityId()}:entityId`, data.entity._id);
      localStorage.setItem(`${getEntityId()}:headerStatement`, JSON.stringify(data.entity.header_statement));

      localStorage.setItem(`${getEntityId()}:logoImage`, JSON.stringify(data.entity.logo));

      localStorage.setItem(`${getEntityId()}:primaryColor`, JSON.stringify(data.entity.primary_color));

      localStorage.setItem(`${getEntityId()}:secondaryColor`, JSON.stringify(data.entity.secondary_color));

      localStorage.setItem(`${getEntityId()}:greetingMessage`, JSON.stringify(data.entity.greeting_message));

      localStorage.setItem(`${getEntityId()}:gradientColor`, JSON.stringify(data.entity.gradient_color));

      localStorage.setItem(`${getEntityId()}:backgroundColor`, JSON.stringify(data.entity.background_color));

      localStorage.setItem(`${getEntityId()}:mainHeading`, JSON.stringify(data.entity.heading));

      localStorage.setItem(`${getEntityId()}:welcomeStatement`, JSON.stringify(data.entity.welcome_statement));
      localStorage.setItem(`${getEntityId()}:status`, JSON.stringify(data?.status));
      localStorage.setItem(`${getEntityId()}:show_language_dropdown`, data.entity.show_language_dropdown);
      localStorage.setItem(`${getEntityId()}:questions`, data.questionCounts > 0 ? true : false);
      setColorProperties();
      setLoader(false);
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    getChatStatus();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      changeStatus(getUserEmail(), false);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    if (getUserEmail()) {
      changeStatus(getUserEmail(), true);
    }
  }, [getUserEmail()]);
  return (
    <>
      {((!entityIdLocal) && (!localStorage.getItem(`${getEntityId()}:entityId`))) ? (
        <SplashScreen />
      ) : (
        <div className="complete_bot">
          <div className="widget overflow-hidden">
            <div className="pluse-animation widget_layout">
              <div className="message_inner_body">
                <div>
                  <Router>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <>
                            <Header
                              setIsNewMessage={setIsNewMessage}
                              route="main"
                              setRedirect={setRedirect}
                              setSelectedAnswers={setSelectedAnswers}
                              setUserChatId={setUserChatId}
                            />
                            <HomeScreen
                              setIsNewMessage={setIsNewMessage}
                              selectedAnswers={selectedAnswers}
                              setRedirect={setRedirect}
                              setUserChatId={setUserChatId}
                            />
                          </>
                        }
                      />
                      <Route
                        path="/form"
                        element={
                          <>
                            <Header
                              setIsNewMessage={setIsNewMessage}
                              route="form"
                              setRedirect={setRedirect}
                              setSelectedAnswers={setSelectedAnswers}
                            />
                            <Form isNewMessage={isNewMessage} selectedAnswers={selectedAnswers} redirect={redirect} />
                          </>
                        }
                      />
                      <Route
                        path="/chat"
                        element={
                          <>
                            <Header
                              setIsNewMessage={setIsNewMessage}
                              route="chat"
                              setRedirect={setRedirect}
                              setSelectedAnswers={setSelectedAnswers}
                            />
                            <ChatScreen
                              isNewMessage={isNewMessage}
                              selectedAnswers={selectedAnswers}
                              setRedirect={setRedirect}
                              userChatId={userChatId}
                              setUserChatId={setUserChatId}
                              setIsNewMessage={setIsNewMessage}
                              setSelectedAnswers={setSelectedAnswers}
                            />
                          </>
                        }
                      />
                      <Route
                        path="/blog"
                        element={
                          <>
                            <Header
                              setIsNewMessage={setIsNewMessage}
                              route="blog"
                              setRedirect={setRedirect}
                              setSelectedAnswers={setSelectedAnswers}
                            />
                            <Blog />
                          </>
                        }
                      />
                      <Route
                        path="/blog-detail"
                        element={
                          <>
                            <Header
                              setIsNewMessage={setIsNewMessage}
                              route="blog-detail"
                              setRedirect={setRedirect}
                              setSelectedAnswers={setSelectedAnswers}
                            />
                            <BlogDetail />
                          </>
                        }
                      />
                      <Route
                        path="/email"
                        element={
                          <>
                            <Header
                              setIsNewMessage={setIsNewMessage}
                              route="email"
                              setRedirect={setRedirect}
                              setSelectedAnswers={setSelectedAnswers}
                            />
                            <EmailTranscript userChatId={userChatId} selectedAnswers={selectedAnswers} />
                          </>
                        }
                      />
                      <Route
                        path="/calendar"
                        element={
                          <>
                            <Header
                              setIsNewMessage={setIsNewMessage}
                              route="calendar"
                              setRedirect={setRedirect}
                              setSelectedAnswers={setSelectedAnswers}
                            />
                            <CalendarScreen />
                          </>
                        }
                      />
                      <Route
                        path="/messages"
                        element={
                          <>
                            <Header
                              setIsNewMessage={setIsNewMessage}
                              route="messages"
                              setRedirect={setRedirect}
                              setSelectedAnswers={setSelectedAnswers}
                            />
                            <MessageScreen setIsNewMessage={setIsNewMessage} setUserChatId={setUserChatId} />
                          </>
                        }
                      />
                    </Routes>
                  </Router>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default App;
