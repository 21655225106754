import React, { useEffect, Suspense, lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEntityId } from '../../helpers/date-conversions';

const Calendar = lazy(() => import('../../Components/Calendar'));

const CalendarScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let email = localStorage.getItem(`${getEntityId()}:userEmail`);
    if (!email) {
      navigate('/form');
    }
    return () => {
      email = '';
    };
  }, []);
  return (
    <div className="">
      <div className="">
        <Suspense fallback={null}>
          <Calendar />
        </Suspense>
      </div>
    </div>
  );
};

export default CalendarScreen;
