import React, { Suspense, useMemo } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import unregister from './serviceWorker';
import setColorProperties from './registerColorProperties';

import App from './App';
import DisbaleScreen from './screens/DisbaleScreen';
import { isSupported } from './helpers/date-conversions';

import loadingGif from './assets/icons/loading.gif';

function MyComponent() {
  unregister();
  const supported = useMemo(() => {
    if (isSupported()) {
      setColorProperties();
      return true;
    }
    return false;
  }, []);

  const renderLoader = () => <img src={loadingGif} alt="loading" style={{ width: '25px' }} />;
  return (
    <React.Fragment>
      <Suspense fallback={renderLoader()}>{supported ? <App /> : <DisbaleScreen />}</Suspense>
    </React.Fragment>
  );
}

ReactDOM.render(<MyComponent />, document.getElementById('root'));
