/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../assets/styles/global.scss';
import '../../App.scss';
import { fetchBlogsData } from '../../shared/api';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import BottomFooter from '../../Components/BottomFooter';
import loadingGif from '../../assets/icons/loading.gif';
import { getEntityId } from '../../helpers/date-conversions';
import { debounce } from 'lodash';

const Blogs = () => {
  const [search, setSearch] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);
  const [blogsData, setBlogsData] = useState([]);
  const [searchBlogsData, setSearchBlogsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [page, setPage] = useState(1);
  const [initialLoad, setInitialLoad] = useState(true); 
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  const fetchBlogs = async (pageNo, searchQuery = '') => {
    setLoading(true);
    try {
      const data = await fetchBlogsData(pageNo, searchQuery, 9);
      if (data.hasMore) {
        if (searchQuery) {
          setSearchBlogsData((prevData) => [...prevData, ...data.blogs]);
        } else {
          setBlogsData((prevData) => [...prevData, ...data.blogs]);
        }
        setHasMoreData(data.hasMore);
        setLoading(false);
      } else {
        if (searchQuery) {
          setSearchBlogsData(data.blogs);
        } else {
          setBlogsData((prevData) => [...prevData, ...data.blogs]);
        }
        setHasMoreData(data.hasMore);
        setLoading(false);
      }
    } catch (e) {
      if (e && e?.response && e?.response?.status === 400) {
      }
    }
  };

  useEffect(() => {
    fetchBlogs(page, search).then(() => {
      setInitialLoad(false); // Mark initial data load as completed
    });
  }, []);

  function handleScroll(event) {
    const iframe = event.target;
    const heightFromBottom = iframe.scrollHeight - (iframe.scrollTop + iframe.clientHeight);
    // Check if the scroll reaches the bottom of the content dynamically
    if (heightFromBottom < 1) {
      if (hasMoreData && !loading) {
        console.log("inside if")
        setLoading(true); 
        setPage((prevPage) => prevPage + 1);
        console.log("handle scroll");
        fetchBlogs(page + 1, search);
      }
    }
  }
  
  function handleSearchChange(value) {
    setSearch(value);
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        handleSearch(value);
      }, 500)
    );
  }

  const debounced = React.useCallback(debounce(fetchBlogs, 500), []);

  function handleSearch(value) {
    debounced(page, value, 5000);
  }
  const handleBlogDetail = (id) => {
    localStorage.setItem(`${getEntityId()}:blogId`, id);
    navigate('/blog-detail', { state: { from: 'blogs' } });
  };

  const HighlightedText = (text, query) => {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));

    const highlightedParts = parts.map((part, index) => {
      if (part.toLowerCase() == query.toLowerCase()) {
        return (
          <span key={index} style={{ backgroundColor: 'yellow' }} className="highlight">
            {part}
          </span>
        );
      } else {
        return <React.Fragment key={index}>{part}</React.Fragment>;
      }
    });

    return <div>{highlightedParts}</div>;
  };

  return (
    <>
     <div className="blogs_screen">
      {blogsData?.length > 0 && (
        <div className="searchOuter">
          <input
            className="searchInput"
            type="text"
            value={search}
            onChange={(event) => handleSearchChange(event.target.value)}
            placeholder="Search for help"
          />
          <SearchIcon className="searchIcon svgIcon" src={search} alt="icon" />
        </div>
      )}
      <div className={`blogs_outer-div-${blogsData.length ? '2' : '1'}`}>
        <div className={`blogs_body_outer px-3`} onScroll={handleScroll}>
          {(!search ? blogsData : searchBlogsData)?.map((blog, index) => (
            <div key={blog._id} className="blogs_body" onClick={() => handleBlogDetail(blog._id)}>
              <img className="blogs_img" src={blog.image} alt="image" />
              <p className="blogs_text">{HighlightedText(blog.title, search)}</p>
            </div>
          ))}

          {hasMoreData && loading && (
            <div className={`d-flex justify-center items-center ${initialLoad ? 'blogs_body_outer_initial' : ''}`} >
              <img src={loadingGif} style={{ width: '25px', height: '25px' }} alt="loading" />
            </div>
          )}

          {!hasMoreData && (
            <>
              {search && searchBlogsData.length === 0 && (
                <div className="blog-set">
                  <h2>No blogs available.</h2>
                </div>
              )}

              {!search && blogsData.length === 0 && (
                <div className="blog-set">
                  <h2>No blogs available.</h2>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="pt-2 pb-2 d-flex justify-center">
        <BottomFooter active="blogs" />
      </div>
    </div>
    </>
  );
};

export default Blogs;
