import { getEntityId } from '../helpers/date-conversions';

const baseUrl = process.env.REACT_APP_INVOCOM_API_URL;
const apiVersion = process.env.REACT_APP_INVOCOM_API_VERSION;
const entityUrl = window.document.referrer ? new URL(window.document.referrer).origin : window.location.url;

export const fetchEntityChats = async (limit = null) => {
  const entityDetails = {
    entityId: localStorage.getItem(`${getEntityId()}:entityId`),
    email: localStorage.getItem(`${getEntityId()}:userEmail`),
    entityUrl,
    limit,
  };
  const entity = 'entity';
  const endPoint = `${baseUrl}/${apiVersion}/${entity}/chats?limit=${limit}`;
  try {
    const response = await fetch(`${endPoint}`, {
      method: 'POST',
      body: JSON.stringify(entityDetails),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const record = await response.json();
      return record.chats;
    }
  } catch (e) {
    return false;
  }
};

export const renderChat = async (chatId) => {
  const entity = 'chat';
  const endPoint = `${baseUrl}/${apiVersion}/${entity}/user/reset_unread_messages`;
  try {
    await fetch(`${endPoint}`, {
      method: 'POST',
      body: JSON.stringify({
        chatId,
        entityUrl,
        email: localStorage.getItem(`${getEntityId()}:userEmail`),
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (e) {
    return false;
  }
};

export const fetchBlogsData = async (pageNo, searchQuery = '', limit = 9, query = null) => {
  try {
    const entity = 'blog';
    const response = await fetch(`${baseUrl}/${apiVersion}/${entity}`, {
      method: 'POST',
      body: JSON.stringify({
        entity: localStorage.getItem(`${getEntityId()}:entityId`),
        page_no: pageNo,
        searchQuery,
        query,
        limit,
        flag: true,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      return data;
    }
  } catch (e) {
    return false;
  }
};
