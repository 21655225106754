/* eslint-disable */
import React, { lazy, Suspense } from 'react';
import loadingGif from '../../assets/icons/loading.gif';

const Attachment = lazy(() => import('../Attachment/index'));

require('dotenv').config();

const Footer = ({ audioUrl, handleImage, allImages, progress }) => {
  const renderLoader = () => <img src={loadingGif} style={{ width: '20px' }} alt="loading" />;
  return (
    <>
      <div className="footer_container ">
        {allImages?.length ? (
          <div
            className="footer_image_container"
            style={{
              width: audioUrl ? '25%' : '100%',
            }}
          >
            {allImages.map((file, index) => (
              <Suspense key={index} fallback={renderLoader()}>
                <Attachment index={index} image={file} handleImage={(e) => handleImage(e)} />
              </Suspense>
            ))}
          </div>
        ) : (
          ''
        )}
        {progress > 0 && progress <= 100 ? (
          <div className="m-1 w-100">
            <div className="d-flex">
              <div className="progress" style={{ width: `${progress}%` }}>
                {' '}
              </div>
              <span className="text-progress">{progress}%</span>
            </div>
          </div>
        ) : (
            ""
          )}
      </div>
    </>
  );
};

export default Footer;
