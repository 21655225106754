/*eslint-disable*/
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/global.scss';
import '../../App.scss';
import { getEntityId } from '../../helpers/date-conversions';
import robotImg from '../../assets/images/robot.webp';
const SubHeader = ({ setUserChatId, setIsNewMessage, setRedirect }) => {
  const navigate = useNavigate();
  const [clientId] = useState(localStorage.getItem(`${getEntityId()}:id`));
  const sendMessage = () => {
    setUserChatId(null);
    setIsNewMessage(true);
    setRedirect(false);
    if (!clientId) {
      navigate('/form');
    } else {
      navigate('/chat');
    }
  };

  return (
    <>
      <div className="outer_layer">
        <div className="inner_layer">
          <div className="main_layer d-flex justify-between items-center text-white pt-5 pb-0 px-5">
            <div className="main_box">
              <h3 className="greeting_text">Hi 👋</h3>
              <p className="meta_text">Start chat with me</p>
              <a className="send_btn" href="#" onClick={() => sendMessage()}>
                Send us a new message
              </a>
            </div>
            <div>
              <img className="robotImg" src={robotImg} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubHeader;
