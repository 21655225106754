/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Chat from '../../Components/Chat';
import { getEntityId } from '../../helpers/date-conversions';

const Chats = ({ setIsNewMessage, setUserChatId, userChatId, selectedAnswers, isNewMessage, }) => {
  const [, setStatus] = useState('Pending');
  const [, setIsChatAssign] = useState(false);
  const [questions, setQuestions] = useState([]);

  useEffect(async () => {
    const getParentQuestion = async () => {
      try {
        const baseUrl = process.env.REACT_APP_INVOCOM_API_URL;
        const apiVersion = process.env.REACT_APP_INVOCOM_API_VERSION;
        const entity = 'questions';
        const response = await fetch(`${baseUrl}/${apiVersion}/${entity}`, {
          method: 'POST',
          body: JSON.stringify({
            entity: localStorage.getItem(`${getEntityId()}:entityId`),
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setQuestions(data.questions);
        if (data?.questions?.length <= 0) {
          localStorage.setItem(`${getEntityId()}:questions`, false);
          localStorage.setItem(`${getEntityId()}:isQuestionRead`, true);
        } else {
          localStorage.setItem(`${getEntityId()}:questions`, true);
        }
      } catch (error) {
        return false;
      }
    };

    if (isNewMessage) {
      await getParentQuestion();
    }
    return () => {
      setQuestions([]);
    };
  }, [isNewMessage]);

  return (
    <Chat
      setStatus={(e) => setStatus(e)}
      selectedAnswers={selectedAnswers}
      questions={questions}
      userChatId={userChatId}
      setUserChatId={setUserChatId}
      setIsNewMessage={setIsNewMessage}
      isNewMessage={isNewMessage}
      setIsChatAssign={setIsChatAssign}
    />
  );
};

export default Chats;
