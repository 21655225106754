import { getEntityId } from '../helpers/date-conversions';

const changeStatus = async (email, isOnline) => {
  const endPoint = `${process.env.REACT_APP_INVOCOM_API_URL}/api/v1/user/change-online-status`;
  const entity = localStorage.getItem(`${getEntityId()}:entityId`);
  await fetch(`${endPoint}`, {
    method: 'POST',
    keepalive: true,
    body: JSON.stringify({
      email: email.toLowerCase(),
      is_online: isOnline,
      entity,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export default changeStatus;
