/* eslint-disable */
import React, { useEffect, useRef } from 'react';

const Question = ({
  question,
  onSelect,
}) => {
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => {
      elementRef.current.scrollIntoView();
    }, []);
    return <div ref={elementRef} />;
  };

  return (
    <div>
      {question.nodes?.map((subQuestion, index) => (
        <div key={`${subQuestion.id}__${index}`} className="d-flex justify-end">
          <div className="send-special-message" style={{ marginBotton: '15px' }}>
            <p onClick={() => onSelect(subQuestion)}>{subQuestion.question}</p>
          </div>
          <AlwaysScrollToBottom />
        </div>
      ))}
    </div>
  );
};

export default Question;
