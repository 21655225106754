/*eslint-disable*/
import React from 'react';
import '../../assets/styles/global.scss';
import '../../App.scss';
import backArrow from '../../assets/icons/backArrow.svg';
import icon from '../../assets/icons/blogIcon.svg';

const CommonHeader = ({ handleBackClick, route }) => {
  return (
    <>
      <div className="blogs_header pt-4 pb-2 px-5 d-flex items-center justify-between">
        <div className="d-flex gap-4">
          <img className="cursor-pointer" onClick={handleBackClick} src={backArrow} alt="icon" />
          <div className="top d-flex gap-2">
            <img src={icon} alt="icon" />
            {route === 'email' ? (
              <h3>Email</h3>
            ) : route === 'calendar' ? (
              <h3>Schedule Meeting</h3>
            ) : route === 'messages' ? (
              <h3>Messages</h3>
            ) : (
              <h3>Blog Detail</h3>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonHeader;
