/* eslint-disable */
import React, { useEffect, useState } from 'react';
import '../../assets/styles/global.scss';
import '../../App.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import changeStatus from '../../service/user';
import loadingGif from '../../assets/icons/loading.gif';
import Box from './Box';
import { getEntityId, setUserEmail, languages, isValidEmail, countryCodes } from '../../helpers/date-conversions';
import Select from 'react-select';
const baseUrl = process.env.REACT_APP_INVOCOM_API_URL;
const apiVersion = process.env.REACT_APP_INVOCOM_API_VERSION;

async function sendUserData(userDetails) {
  try {
    const entity = 'user';
    const response = await fetch(`${baseUrl}/${apiVersion}/${entity}/register`, {
      method: 'POST',
      body: JSON.stringify(userDetails),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    return false;
  }
}

const Form = ({ redirect }) => {
  const entityUrl = window.document.referrer ? new URL(window.document.referrer).origin : window.location.url;
  const [email, setEmail] = useState('');
  const [fullname, setFullname] = useState('');
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('English');
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [contactError, setContactError] = useState('');
  const [entityRecord, setEntityRecord] = useState(localStorage.getItem(`${getEntityId()}:entityId`));
  const [showLanguageDropdown] = useState(JSON.parse(localStorage.getItem(`${getEntityId()}:show_language_dropdown`)));
  const [contact, setContact] = useState(localStorage.getItem(`${getEntityId()}:contact`) || '');
  const [code, setCode] = useState(localStorage.getItem(`${getEntityId()}:countryCode`) || '');
  const [selectedOption, setSelectedOption] = useState(null);
  const [components, setComponents] = useState(['email']);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchEntityByUrl = async () => {
    const url = window.document.referrer ? new URL(window.document.referrer).origin : window.location.url;
    const entityDetails = {
      url,
    };
    const entity = 'user';
    const endPoint = `${process.env.REACT_APP_INVOCOM_API_URL}/api/v1/${entity}/check/url-token`;
    try {
      const response = await fetch(`${endPoint}`, {
        method: 'POST',
        body: JSON.stringify(entityDetails),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        const record = await response.json();
        setEntityRecord(record.entity);
        localStorage.setItem(`${getEntityId()}:entityId`, record.entity);
      }
    } catch (e) {
      return false;
    } 
  };

  useEffect(() => {
    if (localStorage.getItem(`${getEntityId()}:entityId`)) {
      fetchEntityByUrl();
    }
    if (localStorage.getItem(`${getEntityId()}:userEmail`)) {
      setEmail(localStorage.getItem(`${getEntityId()}:userEmail`));
      setFullname(localStorage.getItem(`${getEntityId()}:fullname`));
      setLanguage(localStorage.getItem(`${getEntityId()}:language`));
    }
    return () => {
      setEntityRecord('');
    };
  }, []);

  async function formHandler() {
    try {
      let validContact = true;

      let updatedNameError = '';
      let updatedEmailError = '';
      let updatedContactError = '';

      if (fullname === '') {
        updatedNameError = 'This field is required.';
      }
      if (email === '') {
        updatedEmailError = 'This field is required.';
      } else if (email !== '' && !isValidEmail(email)) {
        updatedEmailError = 'Invalid Email';
      }
      if (code === '' && contact !== '') {
        updatedContactError = 'Please select country code';
        validContact = false;
      } else if ((code !== '' || code === 'Reset') && contact === '') {
        updatedContactError = 'Please enter phone number';
        validContact = false;
      }
      setNameError(updatedNameError);
      setEmailError(updatedEmailError);
      setContactError(updatedContactError);

      if (fullname && isValidEmail(email) && validContact) {
        console.log("fijfirjfr", fullname, email, code, contact)
        setLoading(true);
        const userResponse = await sendUserData({
          email: email.toLowerCase(),
          fullname,
          role: 'USER',
          language,
          entity: entityRecord,
          device_token: false,
          phone_number: contact || '',
          country_code: code || '',
        });
        if (userResponse.status === 200) {
          const record = await userResponse.json();

          localStorage.setItem(`${getEntityId()}:userEmail`, email.toLowerCase());
          setUserEmail(email);
          localStorage.setItem(`${getEntityId()}:fullname`, fullname);
          localStorage.setItem(`${getEntityId()}:language`, language);
          localStorage.setItem(`${getEntityId()}:contact`, record?.data?.user?.phone_number);
          localStorage.setItem(`${getEntityId()}:countryCode`, record?.data?.user?.country_code);
          localStorage.setItem(`${getEntityId()}:id`, record.data.user._id);
          changeStatus(email, true);
          setLoading(false);
          const previousRoute = location?.state?.from;
          if (previousRoute === 'messages') {
            navigate(`/${previousRoute}`);
          } else if (redirect) {
            navigate('/calendar');
          } else {
            navigate('/chat');
          }
        } else if (userResponse.status === 400) {
          setLoading(false);
          navigate('/form');
        }
      }
    } catch (error) {
      console.log("error====>", error)
      return false;
    }
  }

  // Clear error messages
  useEffect(() => {
    setNameError('');
    setEmailError('');
    setContactError('');
  }, [fullname, email, contact, code]);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const maxLength = event.target.maxLength;

    if (inputValue.length <= maxLength) {
      const sanitizedCode = event.target.value.replace(/[^0-9]/g, '');
      setContact(sanitizedCode);
    }
  };
  const options = countryCodes
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((country) => ({
      value: country.code,
      label: `(${country.code}) ${country.name}`,
    }));

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setCode(selectedOption.value);
  };
  const handleKeyDown = (e) => {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight'];
    if (!allowedKeys.includes(e.key) && !/^[0-9+]+$/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleReset = () => {
    setSelectedOption(null);
    setCode('');
  };
  const formatOptionLabel = ({ value, label, country }) => (
    <div>
      <span>{label}</span>
      <span style={{ marginLeft: '5px' }}>{country}</span>
    </div>
  );
  return (
    <>
      {/* <form onSubmit={formHandler}> */}
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
          <img src={loadingGif} alt="loading" style={{ width: '25px' }} />
        </div>
      ) : (
        <div className="form px-5">
          {components.includes('email') ? (
            <Box
              formHandler={() => formHandler()}
              state={setEmail}
              codeState={setCode}
              value={email}
              error={emailError}
              placeHolder="xyz@gmail.com"
              label="Email"
              setComponents={setComponents}
              setError={setEmailError}
              statement="Hello, 👋 Want to chat about InvoCom? I'm here to help you find your way. For Chat can I have your Email?"
            />
          ) : (
            ''
          )}
          {components.includes('fullname') ? (
            <Box
              formHandler={() => formHandler()}
              state={setFullname}
              codeState={setCode}
              value={fullname}
              error={nameError}
              placeHolder="Enter your name"
              label="Fullname"
              setComponents={setComponents}
              setError={setNameError}
              statement="Can I have you name for better conversation?"
            />
          ) : (
            ''
          )}
          {components.includes('phone') ? (
            <Box
              formHandler={() => formHandler()}
              state={setContact}
              codeState={setCode}
              code={code}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              value={contact}
              error={contactError}
              placeHolder="Enter your phone no"
              label="Phone"
              setComponents={setComponents}
              setError={setContactError}
              statement="Can I have your contact for future information?"
            />
          ) : (
            ''
          )}
          {/* 
            <Box state={setFullname} value={fullname} error={nameError} placeHolder="Enter your name" label="Fullname" statement="Can I have you name for better conversation?" />

            <Box state={setFullname} value={fullname} error={nameError} placeHolder="Enter your name" label="Fullname" statement="Can I have you name for better conversation?" /> */}

          {/* <input
              className={`formInput mb-3 ${nameError && 'mb-2'}`}
              placeholder="Full name"
              maxLength={40}
              value={fullname}
              onChange={(e) => {
                const sanitizedCode = e.target.value.replace(/[^A-Za-z\s]/g, '');
                setFullname(sanitizedCode);
              }}
              autoComplete="off"
            />
            {nameError ? (
              <div style={{ color: 'red', fontSize: '12px' }} className="mb-2 ml-2">
                {nameError}
              </div>
            ) : (
              ''
            )} */}

          {/* {showLanguageDropdown ? (
              <div>
                <select className="formInput mb-5" value={language} onChange={(e) => setLanguage(e.target.value)}>
                  {Object.keys(languages).map((key) => (
                    <option value={key} key={key}>
                      {languages[key]}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              ''
            )}
            <div className="contact-flex">
              <div style={{ border: 'none', width: '300px' }}>
                <Select
                  options={options}
                  value={selectedOption}
                  onChange={handleSelectChange}
                  isSearchable={true}
                  placeholder="Select code"
                  formatOptionLabel={formatOptionLabel}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: 'none',
                      boxShadow: 'none',
                      '&:focus': {
                        border: 'none',
                        boxShadow: 'none',
                      },
                    }),
                  }}
                />
              </div>
              <div style={{ width: '100%' }}>
                <input
                  className="formInput pl-0"
                  name="contact"
                  style={{ border: 'none' }}
                  type="text"
                  placeholder="Phone (optional)"
                  value={contact}
                  autoComplete="off"
                  maxLength={25}
                  onInput={handleChange}
                />
              </div>
            </div>
            {selectedOption && (
              <button type="button" className="reset" onClick={handleReset}>
                Reset Code
              </button>
            )}
            <div className="mb-1">
              {contactError ? (
                <div style={{ color: 'red', fontSize: '12px' }} className="mb-2">
                  {contactError}
                </div>
              ) : (
                ''
              )}
            </div>
            {fullname && email ? (
              <button type="submit" className="submitBtn mt-2">
                Submit
              </button>
            ) : (
              <button type="submit" className="submitBtn mt-2 disabledButton">
                Submit
              </button>
            )} */}
        </div>
      )}
      {/* </form> */}
    </>
  );
};

export default Form;
