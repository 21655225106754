/*eslint-disable*/
import React, { useState } from 'react';
import '../../assets/styles/global.scss';
import '../../App.scss';
import dots from '../../assets/icons/menu-dots.svg';
import { useNavigate } from 'react-router-dom';

const SubHeader = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState('');
  const handleEmailTranscript = () => {
    navigate('/email');
    setToggle(false);
  };

  return (
    <>
      <div className="dotsImg" onClick={() => setToggle(!toggle)}>
        <img src={dots} alt="img" />
        {toggle && (
          <ul className="dropdown-menu shadow">
            <li>
              <button onClick={() => handleEmailTranscript()}>Email this transcript</button>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default SubHeader;
