/* eslint-disable */
import React from 'react';
import './tooltip.scss';

const Tooltip = ({ text, children, position }) => {
  return (
    <div className="tooltip-container">
      {children}
      <div className={`tooltip-one ${position}`}>
        {text}
      </div>
    </div>
  );
};

export default Tooltip;