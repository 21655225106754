import React from 'react';
import '../../assets/styles/global.scss';

const DisbaleScreen = () => {
  return (
    <div className="w-md overflow-auto" style={{ margin: '15px auto' }}>
      <div>
        <div className="wrap2 py-2 px-8 border  bg-white shadow-lg rounded-lg m  hover:bg-lightGrey">
          <h2 className="text-gray-800 text-xl font-semibold">Cookies Blocked</h2>
          <p className="mt-1 text-xs text-gray-600">
            This exception is thrown when the &quot;Block third-party cookies and site data&quot; checkbox is set in Content
            Settings. To find the setting, open Chrome settings, type &quot;third&quot; in the search box, click the Cookies
            and other site data Button, and allow Cookies.
          </p>
          <div className="flex justify-end mt-4">
            <a
              href="https://i.stack.imgur.com/cwL58.gif"
              target="_blank"
              rel="noreferrer"
              className="text-sm  underline font-medium text-primary hover:text-indigo-500"
            >
              Settings
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisbaleScreen;
