/* eslint-disable */
let entityValue = '';
let userEmail = '';

export const setEntityId = (value) => {
  entityValue = value;
};

export const getEntityId = () => {
  return entityValue;
};

export const setUserEmail = (value) => {
  userEmail = value;
};

export const getUserEmail = () => {
  return userEmail;
};

export const convertIntoHumanReadableTime = (date) => {
  if(date){
    let time;
    const currentTime = new Date();
    const previousTime = new Date(date);
    const timeDifference = currentTime - previousTime;
  
    if (timeDifference < 60000) {
      time = 'just now';
    } else if (timeDifference < 3600000) {
      const minutes = Math.floor(timeDifference / 60000);
      time = `${minutes} min ago`;
    } else if (timeDifference < 86400000) {
      const hours = Math.floor(timeDifference / 3600000);
      time = `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (timeDifference < 2592000000) {
      const days = Math.floor(timeDifference / 86400000);
      time = `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (timeDifference < 31536000000) {
      const months = Math.floor(timeDifference / 2592000000);
      time = `${months} month${months !== 1 ? 's' : ''} ago`;
    } else {
      const years = Math.floor(timeDifference / 31536000000);
      time = `${years} year${years !== 1 ? 's' : ''} ago`;
    }
    return time;
  }
  return false;
};

export const currentDateFormat = () => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const currentDateSlots = (timeSlots) => {
  const currentDateTimeSlots = [];
  const newDateTime = new Date().toISOString(); // Use ISO format for date string

  if (timeSlots.endTime > newDateTime) {
    let slotStartTime = stringConvertToCurrentTimeFormat(timeSlots.startTime);

    while (slotStartTime < stringConvertToCurrentTimeFormat(timeSlots.endTime)) {
      if (slotStartTime > new Date()) {
        currentDateTimeSlots.push(currentTimeFormat().format(new Date(slotStartTime)));
      }

      slotStartTime = addMinutes(timeSlots.nextSlot, new Date(slotStartTime));
    }
  }
  return currentDateTimeSlots;
};

export const dateTimeSlots = (timeSlots) => {
  const times = [];
  let slotTime = new Date(stringConvertToCurrentTimeFormat(timeSlots.startTime));
  const endTime = new Date(stringConvertToCurrentTimeFormat(timeSlots.endTime));
  while (slotTime < endTime) {
    times.push(dateConvertToCurrentTimeFormat(slotTime));
    slotTime = addMinutes(timeSlots.nextSlot, slotTime);
  }
  return times;
};

export const addMins = (timeStr, minutes) => {
  const [hourStr, minuteStr] = timeStr.split(':');
  let hour = parseInt(hourStr);
  let minute = parseInt(minuteStr);

  // Add 30 minutes
  minute += minutes;
  if (minute >= 60) {
    minute -= 60;
    hour += 1;
  }

  // Format the output
  let newHourStr = hour.toString();
  let newMinuteStr = minute.toString();
  if (hour < 10) {
    newHourStr = '0' + newHourStr;
  }
  if (minute < 10) {
    newMinuteStr = '0' + newMinuteStr;
  }

  return newHourStr + ':' + newMinuteStr;
};

export const currentTimeFormat = () => {
  return new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
};

export const LocalTimeFormat = () => {
  return new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

export const stringConvertToCurrentTimeFormat = (hms) => {
  const splitTime = hms.split(/[/ :]/);
  return new Date().setHours(splitTime[0], splitTime[1], 0, 0);
};

export const dateConvertToCurrentTimeFormat = (date) => {
  const splitTime = date
    .toTimeString()
    .replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1')
    .split(':');
  return `${splitTime[0]}:${splitTime[1]}`;
};

export const addMinutes = (numOfMinutes, date = new Date()) => {
  date.setMinutes(date.getMinutes() + numOfMinutes);
  return date;
};

export const addDays = (numOfDays, date) => {
  return new Date(date.getTime() + numOfDays * 24 * 60 * 60 * 1000);
};

export const numOfDays = (startDate, endDate) => {
  const differenceInTime = endDate.getTime() - startDate.getTime();
  return differenceInTime / (1000 * 3600 * 24);
};

export const formatDate = (date) => {
  const current = new Date(date);
  const year = current.getFullYear().toString();
  const month = (current.getMonth() + 101).toString().substring(1);
  const day = (current.getDate() + 100).toString().substring(1);
  return `${year} ${month} ${day}`;
};

export const isSupported = () => {
  try {
    return navigator.cookieEnabled;
  } catch (e) {
    return false;
  }
};

export const languages = {
  Albanian: 'Albanian',
  Amharic: 'Amharic',
  Arabic: 'Arabic',
  Armenian: 'Armenian',
  Azerbaijani: 'Azerbaijani',
  Basque: 'Basque',
  Belarusian: 'Belarusian',
  Bengali: 'Bengali',
  Bosnian: 'Bosnian',
  Bulgarian: 'Bulgarian',
  Catalan: 'Catalan',
  Cebuano: 'Cebuano',
  Chichewa: 'Chichewa',
  Chinese: 'Chinese',
  Corsican: 'Corsican',
  Croatian: 'Croatian',
  Czech: 'Czech',
  Danish: 'Danish',
  Dutch: 'Dutch',
  English: 'English',
  Esperanto: 'Esperanto',
  Estonian: 'Estonian',
  Filipino: 'Filipino',
  Finnish: 'Finnish',
  French: 'French',
  Frisian: 'Frisian',
  Galician: 'Galician',
  Georgian: 'Georgian',
  German: 'German',
  Greek: 'Greek',
  Gujarati: 'Gujarati',
  HaitianCreole: 'HaitianCreole',
  Hausa: 'Hausa',
  Hawaiian: 'Hawaiian',
  Hebrew: 'Hebrew',
  Hindi: 'Hindi',
  Hmong: 'Hmong',
  Hungarian: 'Hungarian',
  Icelandic: 'Icelandic',
  Igbo: 'Igbo',
  Indonesian: 'Indonesian',
  Irish: 'Irish',
  Italian: 'Italian',
  Japanese: 'Japanese',
  Javanese: 'Javanese',
  Kannada: 'Kannada',
  Kazakh: 'Kazakh',
  Khmer: 'Khmer',
  Korean: 'Korean',
  Kurdish: 'Kurdish (Kurmanji)',
  Kyrgyz: 'Kyrgyz',
  Lao: 'Lao',
  Latin: 'Latin',
  Latvian: 'Latvian',
  Lithuanian: 'Lithuanian',
  Luxembourgish: 'Luxembourgish',
  Macedonian: 'Macedonian',
  Malagasy: 'Malagasy',
  Malay: 'Malay',
  Malayalam: 'Malayalam',
  Maltese: 'Maltese',
  Maori: 'Maori',
  Marathi: 'Marathi',
  Mongolian: 'Mongolian',
  Myanmar: 'Myanmar',
  Nepali: 'Nepali',
  Norwegian: 'Norwegian',
  Odia: 'Odia',
  Pashto: 'Pashto',
  Persian: 'Persian',
  Polish: 'Polish',
  Portuguese: 'Portuguese',
  Punjabi: 'Punjabi',
  Romanian: 'Romanian',
  Russian: 'Russian',
  Samoan: 'Samoan',
  ScotsGaelic: 'Scots Gaelic',
  Sesotho: 'Sesotho',
  Shona: 'Shona',
  Sindhi: 'Sindhi',
  Sinhala: 'Sinhala',
  Slovak: 'Slovak',
  Slovenian: 'Slovenian',
  Somali: 'Somali',
  Spanish: 'Spanish',
  Sundanese: 'Sundanese',
  Swahili: 'Swahili',
  Swedish: 'Swedish ',
  Tajik: 'Tajik',
  Tamil: 'Tamil',
  Tatar: 'Tatar',
  Telugu: 'Telugu',
  Thai: 'Thai',
  Tibetan: 'Tibetan',
  Tonga: 'Tonga',
  Turkish: 'Turkish',
  Turkmen: 'Turkmen',
  Ukrainian: 'Ukrainian',
  Urdu: 'Urdu',
  Uyghur: 'Uyghur',
  Uzbek: 'Uzbek',
  Vietnamese: 'Vietnamese',
  Welsh: 'Welsh',
  Xhosa: 'Xhosa',
  Yiddish: 'Yiddish',
  Yoruba: 'Yoruba',
  Zulu: 'Zulu',
};

export const isValidEmail = (email) => {
  return /^(([^!<>()#[\]\\.,;:\s@"]+(\.[^!<>#()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};
export const countryCodes = [
  {
    "code": "+7 840",
    "name": "Abkhazia"
  },
  {
    "code": "+93",
    "name": "Afghanistan"
  },
  {
    "code": "+355",
    "name": "Albania"
  },
  {
    "code": "+213",
    "name": "Algeria"
  },
  {
    "code": "+1 684",
    "name": "American Samoa"
  },
  {
    "code": "+376",
    "name": "Andorra"
  },
  {
    "code": "+244",
    "name": "Angola"
  },
  {
    "code": "+1 264",
    "name": "Anguilla"
  },
  {
    "code": "+1 268",
    "name": "Antigua and Barbuda"
  },
  {
    "code": "+54",
    "name": "Argentina"
  },
  {
    "code": "+374",
    "name": "Armenia"
  },
  {
    "code": "+297",
    "name": "Aruba"
  },
  {
    "code": "+247",
    "name": "Ascension"
  },
  {
    "code": "+61",
    "name": "Australia"
  },
  {
    "code": "+672",
    "name": "Australian External Territories"
  },
  {
    "code": "+43",
    "name": "Austria"
  },
  {
    "code": "+994",
    "name": "Azerbaijan"
  },
  {
    "code": "+1 242",
    "name": "Bahamas"
  },
  {
    "code": "+973",
    "name": "Bahrain"
  },
  {
    "code": "+880",
    "name": "Bangladesh"
  },
  {
    "code": "+1 246",
    "name": "Barbados"
  },
  {
    "code": "+1 268",
    "name": "Barbuda"
  },
  {
    "code": "+375",
    "name": "Belarus"
  },
  {
    "code": "+32",
    "name": "Belgium"
  },
  {
    "code": "+501",
    "name": "Belize"
  },
  {
    "code": "+229",
    "name": "Benin"
  },
  {
    "code": "+1 441",
    "name": "Bermuda"
  },
  {
    "code": "+975",
    "name": "Bhutan"
  },
  {
    "code": "+591",
    "name": "Bolivia"
  },
  {
    "code": "+387",
    "name": "Bosnia and Herzegovina"
  },
  {
    "code": "+267",
    "name": "Botswana"
  },
  {
    "code": "+55",
    "name": "Brazil"
  },
  {
    "code": "+246",
    "name": "British Indian Ocean Territory"
  },
  {
    "code": "+1 284",
    "name": "British Virgin Islands"
  },
  {
    "code": "+673",
    "name": "Brunei"
  },
  {
    "code": "+359",
    "name": "Bulgaria"
  },
  {
    "code": "+226",
    "name": "Burkina Faso"
  },
  {
    "code": "+257",
    "name": "Burundi"
  },
  {
    "code": "+855",
    "name": "Cambodia"
  },
  {
    "code": "+237",
    "name": "Cameroon"
  },
  {
    "code": "+1",
    "name": "Canada"
  },
  {
    "code": "+238",
    "name": "Cape Verde"
  },
  {
    "code": "+ 345",
    "name": "Cayman Islands"
  },
  {
    "code": "+236",
    "name": "Central African Republic"
  },
  {
    "code": "+235",
    "name": "Chad"
  },
  {
    "code": "+56",
    "name": "Chile"
  },
  {
    "code": "+86",
    "name": "China"
  },
  {
    "code": "+61",
    "name": "Christmas Island"
  },
  {
    "code": "+61",
    "name": "Cocos-Keeling Islands"
  },
  {
    "code": "+57",
    "name": "Colombia"
  },
  {
    "code": "+269",
    "name": "Comoros"
  },
  {
    "code": "+242",
    "name": "Congo"
  },
  {
    "code": "+243",
    "name": "Congo, Dem. Rep. of (Zaire)"
  },
  {
    "code": "+682",
    "name": "Cook Islands"
  },
  {
    "code": "+506",
    "name": "Costa Rica"
  },
  {
    "code": "+385",
    "name": "Croatia"
  },
  {
    "code": "+53",
    "name": "Cuba"
  },
  {
    "code": "+599",
    "name": "Curacao"
  },
  {
    "code": "+537",
    "name": "Cyprus"
  },
  {
    "code": "+420",
    "name": "Czech Republic"
  },
  {
    "code": "+45",
    "name": "Denmark"
  },
  {
    "code": "+246",
    "name": "Diego Garcia"
  },
  {
    "code": "+253",
    "name": "Djibouti"
  },
  {
    "code": "+1 767",
    "name": "Dominica"
  },
  {
    "code": "+1 809",
    "name": "Dominican Republic"
  },
  {
    "code": "+670",
    "name": "East Timor"
  },
  {
    "code": "+56",
    "name": "Easter Island"
  },
  {
    "code": "+593",
    "name": "Ecuador"
  },
  {
    "code": "+20",
    "name": "Egypt"
  },
  {
    "code": "+503",
    "name": "El Salvador"
  },
  {
    "code": "+240",
    "name": "Equatorial Guinea"
  },
  {
    "code": "+291",
    "name": "Eritrea"
  },
  {
    "code": "+372",
    "name": "Estonia"
  },
  {
    "code": "+251",
    "name": "Ethiopia"
  },
  {
    "code": "+500",
    "name": "Falkland Islands"
  },
  {
    "code": "+298",
    "name": "Faroe Islands"
  },
  {
    "code": "+679",
    "name": "Fiji"
  },
  {
    "code": "+358",
    "name": "Finland"
  },
  {
    "code": "+33",
    "name": "France"
  },
  {
    "code": "+596",
    "name": "French Antilles"
  },
  {
    "code": "+594",
    "name": "French Guiana"
  },
  {
    "code": "+689",
    "name": "French Polynesia"
  },
  {
    "code": "+241",
    "name": "Gabon"
  },
  {
    "code": "+220",
    "name": "Gambia"
  },
  {
    "code": "+995",
    "name": "Georgia"
  },
  {
    "code": "+49",
    "name": "Germany"
  },
  {
    "code": "+233",
    "name": "Ghana"
  },
  {
    "code": "+350",
    "name": "Gibraltar"
  },
  {
    "code": "+30",
    "name": "Greece"
  },
  {
    "code": "+299",
    "name": "Greenland"
  },
  {
    "code": "+1 473",
    "name": "Grenada"
  },
  {
    "code": "+590",
    "name": "Guadeloupe"
  },
  {
    "code": "+1 671",
    "name": "Guam"
  },
  {
    "code": "+502",
    "name": "Guatemala"
  },
  {
    "code": "+224",
    "name": "Guinea"
  },
  {
    "code": "+245",
    "name": "Guinea-Bissau"
  },
  {
    "code": "+595",
    "name": "Guyana"
  },
  {
    "code": "+509",
    "name": "Haiti"
  },
  {
    "code": "+504",
    "name": "Honduras"
  },
  {
    "code": "+852",
    "name": "Hong Kong SAR China"
  },
  {
    "code": "+36",
    "name": "Hungary"
  },
  {
    "code": "+354",
    "name": "Iceland"
  },
  {
    "code": "+91",
    "name": "India"
  },
  {
    "code": "+62",
    "name": "Indonesia"
  },
  {
    "code": "+98",
    "name": "Iran"
  },
  {
    "code": "+964",
    "name": "Iraq"
  },
  {
    "code": "+353",
    "name": "Ireland"
  },
  {
    "code": "+972",
    "name": "Israel"
  },
  {
    "code": "+39",
    "name": "Italy"
  },
  {
    "code": "+225",
    "name": "Ivory Coast"
  },
  {
    "code": "+1 876",
    "name": "Jamaica"
  },
  {
    "code": "+81",
    "name": "Japan"
  },
  {
    "code": "+962",
    "name": "Jordan"
  },
  {
    "code": "+7 7",
    "name": "Kazakhstan"
  },
  {
    "code": "+254",
    "name": "Kenya"
  },
  {
    "code": "+686",
    "name": "Kiribati"
  },
  {
    "code": "+965",
    "name": "Kuwait"
  },
  {
    "code": "+996",
    "name": "Kyrgyzstan"
  },
  {
    "code": "+856",
    "name": "Laos"
  },
  {
    "code": "+371",
    "name": "Latvia"
  },
  {
    "code": "+961",
    "name": "Lebanon"
  },
  {
    "code": "+266",
    "name": "Lesotho"
  },
  {
    "code": "+231",
    "name": "Liberia"
  },
  {
    "code": "+218",
    "name": "Libya"
  },
  {
    "code": "+423",
    "name": "Liechtenstein"
  },
  {
    "code": "+370",
    "name": "Lithuania"
  },
  {
    "code": "+352",
    "name": "Luxembourg"
  },
  {
    "code": "+853",
    "name": "Macau SAR China"
  },
  {
    "code": "+389",
    "name": "Macedonia"
  },
  {
    "code": "+261",
    "name": "Madagascar"
  },
  {
    "code": "+265",
    "name": "Malawi"
  },
  {
    "code": "+60",
    "name": "Malaysia"
  },
  {
    "code": "+960",
    "name": "Maldives"
  },
  {
    "code": "+223",
    "name": "Mali"
  },
  {
    "code": "+356",
    "name": "Malta"
  },
  {
    "code": "+692",
    "name": "Marshall Islands"
  },
  {
    "code": "+596",
    "name": "Martinique"
  },
  {
    "code": "+222",
    "name": "Mauritania"
  },
  {
    "code": "+230",
    "name": "Mauritius"
  },
  {
    "code": "+262",
    "name": "Mayotte"
  },
  {
    "code": "+52",
    "name": "Mexico"
  },
  {
    "code": "+691",
    "name": "Micronesia"
  },
  {
    "code": "+1 808",
    "name": "Midway Island"
  },
  {
    "code": "+373",
    "name": "Moldova"
  },
  {
    "code": "+377",
    "name": "Monaco"
  },
  {
    "code": "+976",
    "name": "Mongolia"
  },
  {
    "code": "+382",
    "name": "Montenegro"
  },
  {
    "code": "+1664",
    "name": "Montserrat"
  },
  {
    "code": "+212",
    "name": "Morocco"
  },
  {
    "code": "+95",
    "name": "Myanmar"
  },
  {
    "code": "+264",
    "name": "Namibia"
  },
  {
    "code": "+674",
    "name": "Nauru"
  },
  {
    "code": "+977",
    "name": "Nepal"
  },
  {
    "code": "+31",
    "name": "Netherlands"
  },
  {
    "code": "+599",
    "name": "Netherlands Antilles"
  },
  {
    "code": "+1 869",
    "name": "Nevis"
  },
  {
    "code": "+687",
    "name": "New Caledonia"
  },
  {
    "code": "+64",
    "name": "New Zealand"
  },
  {
    "code": "+505",
    "name": "Nicaragua"
  },
  {
    "code": "+227",
    "name": "Niger"
  },
  {
    "code": "+234",
    "name": "Nigeria"
  },
  {
    "code": "+683",
    "name": "Niue"
  },
  {
    "code": "+672",
    "name": "Norfolk Island"
  },
  {
    "code": "+850",
    "name": "North Korea"
  },
  {
    "code": "+1 670",
    "name": "Northern Mariana Islands"
  },
  {
    "code": "+47",
    "name": "Norway"
  },
  {
    "code": "+968",
    "name": "Oman"
  },
  {
    "code": "+92",
    "name": "Pakistan"
  },
  {
    "code": "+680",
    "name": "Palau"
  },
  {
    "code": "+970",
    "name": "Palestinian Territory"
  },
  {
    "code": "+507",
    "name": "Panama"
  },
  {
    "code": "+675",
    "name": "Papua New Guinea"
  },
  {
    "code": "+595",
    "name": "Paraguay"
  },
  {
    "code": "+51",
    "name": "Peru"
  },
  {
    "code": "+63",
    "name": "Philippines"
  },
  {
    "code": "+48",
    "name": "Poland"
  },
  {
    "code": "+351",
    "name": "Portugal"
  },
  {
    "code": "+1 787",
    "name": "Puerto Rico"
  },
  {
    "code": "+974",
    "name": "Qatar"
  },
  {
    "code": "+262",
    "name": "Reunion"
  },
  {
    "code": "+40",
    "name": "Romania"
  },
  {
    "code": "+7",
    "name": "Russia"
  },
  {
    "code": "+250",
    "name": "Rwanda"
  },
  {
    "code": "+685",
    "name": "Samoa"
  },
  {
    "code": "+378",
    "name": "San Marino"
  },
  {
    "code": "+966",
    "name": "Saudi Arabia"
  },
  {
    "code": "+221",
    "name": "Senegal"
  },
  {
    "code": "+381",
    "name": "Serbia"
  },
  {
    "code": "+248",
    "name": "Seychelles"
  },
  {
    "code": "+232",
    "name": "Sierra Leone"
  },
  {
    "code": "+65",
    "name": "Singapore"
  },
  {
    "code": "+421",
    "name": "Slovakia"
  },
  {
    "code": "+386",
    "name": "Slovenia"
  },
  {
    "code": "+677",
    "name": "Solomon Islands"
  },
  {
    "code": "+27",
    "name": "South Africa"
  },
  {
    "code": "+500",
    "name": "South Georgia and the South Sandwich Islands"
  },
  {
    "code": "+82",
    "name": "South Korea"
  },
  {
    "code": "+34",
    "name": "Spain"
  },
  {
    "code": "+94",
    "name": "Sri Lanka"
  },
  {
    "code": "+249",
    "name": "Sudan"
  },
  {
    "code": "+597",
    "name": "Suriname"
  },
  {
    "code": "+268",
    "name": "Swaziland"
  },
  {
    "code": "+46",
    "name": "Sweden"
  },
  {
    "code": "+41",
    "name": "Switzerland"
  },
  {
    "code": "+963",
    "name": "Syria"
  },
  {
    "code": "+886",
    "name": "Taiwan"
  },
  {
    "code": "+992",
    "name": "Tajikistan"
  },
  {
    "code": "+255",
    "name": "Tanzania"
  },
  {
    "code": "+66",
    "name": "Thailand"
  },
  {
    "code": "+670",
    "name": "Timor Leste"
  },
  {
    "code": "+228",
    "name": "Togo"
  },
  {
    "code": "+690",
    "name": "Tokelau"
  },
  {
    "code": "+676",
    "name": "Tonga"
  },
  {
    "code": "+1 868",
    "name": "Trinidad and Tobago"
  },
  {
    "code": "+216",
    "name": "Tunisia"
  },
  {
    "code": "+90",
    "name": "Turkey"
  },
  {
    "code": "+993",
    "name": "Turkmenistan"
  },
  {
    "code": "+1 649",
    "name": "Turks and Caicos Islands"
  },
  {
    "code": "+688",
    "name": "Tuvalu"
  },
  {
    "code": "+1 340",
    "name": "U.S. Virgin Islands"
  },
  {
    "code": "+256",
    "name": "Uganda"
  },
  {
    "code": "+380",
    "name": "Ukraine"
  },
  {
    "code": "+971",
    "name": "United Arab Emirates"
  },
  {
    "code": "+44",
    "name": "United Kingdom"
  },
  {
    "code": "+1",
    "name": "United States"
  },
  {
    "code": "+598",
    "name": "Uruguay"
  },
  {
    "code": "+998",
    "name": "Uzbekistan"
  },
  {
    "code": "+678",
    "name": "Vanuatu"
  },
  {
    "code": "+58",
    "name": "Venezuela"
  },
  {
    "code": "+84",
    "name": "Vietnam"
  },
  {
    "code": "+1 808",
    "name": "Wake Island"
  },
  {
    "code": "+681",
    "name": "Wallis and Futuna"
  },
  {
    "code": "+967",
    "name": "Yemen"
  },
  {
    "code": "+260",
    "name": "Zambia"
  },
  {
    "code": "+255",
    "name": "Zanzibar"
  },
  {
    "code": "+263",
    "name": "Zimbabwe"
  }
];
