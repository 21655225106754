/* eslint-disable */
import AWS from 'aws-sdk';

const s3 = new AWS.S3({
  region: process.env.REACT_APP_AWS_AUDIO_REGION,
  accessKeyId: process.env.REACT_APP_AWS_AUDIO_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_AUDIO_SECRET_KEY_ID,
});

export const UploadAudioToS3 = async (Key, Body) => {
  const params = {
    Bucket: process.env.REACT_APP_AWS_AUDIO_BUCKET_NAME,
    Key,
    Body,
    ACL: 'public-read',
  };
  try {
    const response = await s3
      .upload(params, { queueSize: 1 })
      .on('httpUploadProgress', (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      })
      .promise();
    return response;
  } catch (error) {
    console.log('uploading error', error);
  }
};
