/* eslint-disable */
import React, { useState } from 'react';
import loadingGif from '../../assets/icons/loading.gif';
import { getEntityId } from '../../helpers/date-conversions';
import { useNavigate } from 'react-router-dom';
const baseUrl = process.env.REACT_APP_INVOCOM_API_URL;
const apiVersion = process.env.REACT_APP_INVOCOM_API_VERSION;

async function sendUserData(userDetails) {
  try {
    const entity = 'user';
    const response = await fetch(`${baseUrl}/${apiVersion}/${entity}/email/transcript`, {
      method: 'POST',
      body: JSON.stringify(userDetails),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    return false;
  }
}

const EmailTranscriptScreen = ({ selectedAnswers, userChatId }) => {
  const navigate = useNavigate();
  const isValidEmail = (email) => {
    return /^(([^!<>()#[\]\\.,;:\s@"]+(\.[^!<>#()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };
  const userEmail = localStorage.getItem(`${getEntityId()}:userEmail`);
  const [email, setEmail] = useState(userEmail || '');
  const [emailRecord, setEmailRecord] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [entityRecord] = useState(localStorage.getItem(`${getEntityId()}:entityId`));
  const [clientId] = useState(localStorage.getItem(`${getEntityId()}:id`));

  async function formHandler(e) {
    e.preventDefault();
    try {
      if (email === '') {
        setEmailError('Email must not be empty');
      } else if (email !== '' && !isValidEmail(email)) {
        setEmailError('Invalid Email');
      } else {
        setEmailError('');
      }
      if (isValidEmail(email)) {
        setEmailSent(false);
        setLoading(true);
        const userResponse = await sendUserData({
          email,
          entity: entityRecord,
          userId: clientId,
          userChatId,
          answers: selectedAnswers,
        });
        if (userResponse.status === 200) {
          setLoading(false);
          setEmail('');
          setEmailRecord(email);
          setEmailSent(true);
          navigate('/email');
        } else if (userResponse.status === 400) {
          setLoading(false);
          setEmailError('There is no chat that we can send via email.');
          navigate('/email');
        }
      }
    } catch (error) {
      return false;
    }
  }

  const goBackToConversation = () => {
    navigate(userEmail ? '/chat' : '/',  { state: { from: 'email-transcript' } });
  };

  return (
    <div className="form_body overflow-auto flex justify-center items-center">
      <>
        <div>
          {!emailSent ? (
            <div className={`fade-animation ${emailError && 'custom-form'}`}>
              <form className="form px-5 mt-5" onSubmit={formHandler}>
                <div className="mb-3">
                  <input
                    className={`shadow-lg formInput ${emailError && 'margin-bottom-3'}`}
                    name="email"
                    type="text"
                    placeholder="Email"
                    value={email}
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError ? (
                    <div style={{ color: 'red', fontSize: '12px' }} className="mb-2">
                      {emailError}
                    </div>
                  ) : (
                    ''
                  )}
                  <p className="mt-2">
                    Please enter the email address where you&apos;d like to send the transcript of this conversation
                  </p>
                </div>
                <div className="slider_container flex justify-center">
                  <div className="slick_btn_container w-100 flex justify-center">
                    {loading ? (
                      <img src={loadingGif} alt="loading" style={{ width: '25px' }} />
                    ) : (
                      <button className="submitBtn" type="submit">
                        Send Email{' '}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="form px-5 mt-5">
              <div className="d-flex justify-center items-center flex-col">
                <h2 className="text-xl mb-1">Email Sent</h2>
                <p className="text-center">
                  A transcript of this conversation has been emailed to {emailRecord}. If you don't receive an email within a
                  few minutes, please check your Spam folder.
                </p>
                <button className="submitBtn" onClick={() => goBackToConversation()}>
                  Go back to conversation
                </button>
              </div>
            </div>
          )}
        </div>
      </>
    </div>
  );
};
export default EmailTranscriptScreen;
