/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/global.scss';
import '../../App.scss';
import { ReactComponent as MeetingIcon } from '../../assets/icons/meetingIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as NextArrow } from '../../assets/icons/next-arrow.svg';
import loadingGif from '../../assets/icons/loading.gif';
import { getEntityId, convertIntoHumanReadableTime } from '../../helpers/date-conversions';
import BottomFooter from '../../Components/BottomFooter';
import { ReactComponent as Logo } from '../../assets/icons/invocom-icon-chat.svg';
import { fetchEntityChats, renderChat, fetchBlogsData, handleBlogDetail } from '../../shared/api';
import { debounce } from 'lodash';
import Tooltip from "../../Components/Tooltip/index";

const sound = './Email.mp3';

const HomeScreen = ({ setIsNewMessage, setUserChatId, setRedirect }) => {
  const [recentChatLoading, setRecentChatLoading] = useState(false);
  const [blogLoading, setBlogLoading] = useState(false);
  const [entityChats, setEntityChats] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();
  const mainHeading = JSON.parse(localStorage.getItem(`${getEntityId()}:mainHeading`));
  const [search, setSearch] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [userId] = useState(localStorage.getItem(`${getEntityId()}:id`));
  const fetchBlogs = async (pageNo, searchQuery = '') => {
    setBlogLoading(true);
    try {
      const data = await fetchBlogsData(pageNo, searchQuery, 9);
      if (data.hasMore) {
        if (searchQuery) {
          setSearchData((prevData) => [...prevData, ...data.blogs]);
        } else {
          setBlogs((prevData) => [...prevData, ...data.blogs]);
        }
        setBlogLoading(false);
      } else {
        if (searchQuery) {
          setSearchData(data.blogs);
        } else {
          setBlogs(data.blogs);
        }
        setBlogLoading(false);
      }
    } catch (e) {
      if (e && e?.response && e?.response?.status === 400) {
      }
    }
  };

  useEffect(async () => {
    fetchBlogs(1, search);
  }, []);

  const debounced = React.useCallback(debounce(fetchBlogs, 500), []);

  const filterSearchData = (value) => {
    setSearch(value);
    debounced(1, value, 5000);
  };

  const handleBlogDetail = (id) => {
    localStorage.setItem(`${getEntityId()}:blogId`, id);
    navigate('/blog-detail');
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_INVOCOM_API_URL, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
      transports: ['websocket'],
      upgrade: false,
    });
    const urlAudio = new Audio();

    const id = localStorage.getItem(`${getEntityId()}:id`);
    const entityRecord = localStorage.getItem(`${getEntityId()}:entityId`);
    socket.on(`sendNewMessageToClient-${userId}`, async (updatedChat) => {
      urlAudio.src = sound;
      urlAudio.autoplay = true;
      urlAudio.type = 'audio/mp3';
      setTimeout(function () {
        urlAudio.play();
      }, 100);
      if (updatedChat?.client?._id === id) {
        const updatedChat = await fetchEntityChats(2);
        setEntityChats(updatedChat);
      }
      urlAudio.pause();
    });
    return () => {
      setEntityChats([]);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem(`${getEntityId()}:status`) === 'ASSIGNED') {
      navigate('/form');
    }
  }, [localStorage.getItem(`${getEntityId()}:status`)]);

  const blogsData = () => {
    navigate('/blog');
  };

  useEffect(async () => {
    setRecentChatLoading(false);
    if (localStorage.getItem(`${getEntityId()}:userEmail`)) {
      const chats = await fetchEntityChats(2);
      setEntityChats(chats);
    }
    setRecentChatLoading(false);
    return () => {
      setEntityChats([]);
    };
  }, [localStorage.getItem(`${getEntityId()}:userEmail`)]);

  return (
    <div className="position-relative">
      <div className="px-5 main_body">
        <div className="d-flex justify-between mb-3 pt-3 card_flex">
          <div className="meeting_card">
            <a
              href="#"
              onClick={() => {
                setRedirect(true);
                setIsNewMessage(true);
                navigate('/calendar');
              }}
              className="outer_container"
            >
              <MeetingIcon className="svgIcon" />
              <span>Schedule a meeting</span>
            </a>
          </div>
          <div className="meeting_card">
            <a href="#" className="outer_container" onClick={() => blogsData()}>
              <SearchIcon className="svgIcon" />
              <div>Search for help</div>
            </a>
          </div>
        </div>
        {recentChatLoading ? (
          <div className="main-screen-loader">
            <img src={loadingGif} style={{ width: '25px' }} alt="loading" />
          </div>
        ) : entityChats.length > 0 ? (
          <div className="recent_message_wrap">
            <span className="text size mb-2 block">Recent messages</span>
            <div
              className="messages-body"
              style={{ padding: '0px', marginLeft: '-20px', marginRight: '-20px', maxHeight: '150px' }}
            >
              <ul className="lists">
                {entityChats?.map((chat) => {
                  const time = convertIntoHumanReadableTime(
                    chat.messages.length > 0 ? chat.messages[chat.messages.length - 1]?.updatedAt : chat.createdAt
                  );
                  return (
                    <li
                      key={chat._id}
                      className="list"
                      onClick={() => {
                        setIsNewMessage(false);
                        setUserChatId(chat._id);
                        renderChat(chat._id);
                        localStorage.setItem(`${getEntityId()}:questions`, true);
                        navigate('/chat', { state: { from: '/' } });
                      }}
                    >
                      <div className="d-flex message-box">
                        <div className="image">
                          <div className="">
                            <Logo />
                          </div>
                        </div>
                        <div className="text">
                          <div className="column">
                            <div className="d-flex" style={{position: "relative"}} >
                              <div className="admin-text">{mainHeading}</div>
                              {chat.clientUnReadMessages !== 0 ? (
                                <span className="unReadMessage">{chat.clientUnReadMessages}</span>
                              ) : (
                                ''
                              )}
                               
                              <div style={{ right: '0px', top: '0', position: "absolute" }} >
                                <Tooltip text="Go on Chat" position="left">
                                  <NextArrow />
                                </Tooltip>
                              </div>
                              
                            </div>
                            <div className="admin">
                              <div className="text-wrap">
                                <span className="white-space">
                                  {chat.messages.length > 0
                                    ? chat.messages[chat.messages.length - 1]?.message
                                      ? chat.messages[chat.messages.length - 1]?.message
                                      : chat.messages[chat.messages.length - 1]?.audio
                                      ? 'Audio'
                                      : chat.messages[chat.messages.length - 1]?.image
                                      ? 'Image'
                                      : 'No New Message'
                                    : 'No New Message'}
                                </span>
                              </div>
                              <div className="date">{time ? ` • ${time}` : ''}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="recent_message_wrap blogs-wrap mt-3 mb-5" style={{ padding: '16px 10px' }}>
          {blogs?.length > 0 ? (
            <div className="searchOuter d-flex">
              <input
                className="searchInput w-100"
                type="text"
                value={search}
                onChange={(event) => {
                  filterSearchData(event.target.value);
                }}
                placeholder="Search for help"
              />
              <SearchIcon className="searchIcon svgIcon" src={search} alt="icon" />
            </div>
          ) : (
            ''
          )}
          {/* blogs_body_outer-2 px-5 d-flex justify-center items-center */}
          <div className="blogs-body">
            <ul className="lists">
              {blogLoading ? (
                <div className="no-results show">
                  <div
                    className={`${
                      blogs?.length > 0 ? 'blogs_body_outer' : 'blogs_body_outer-2'
                    } px-5 d-flex justify-center items-center`}
                  >
                    <img src={loadingGif} style={{ width: '25px', height: '25px' }} alt="loading" />
                  </div>
                </div>
              ) : search && searchData.length === 0 ? (
                <li>
                  <div className="d-flex p-2 mb-2 mb-2 w-100" style={{ cursor: 'pointer' }}>
                    <div style={{ width: '90%' }}>
                      <div
                        className="text-center"
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        No blogs available
                      </div>
                    </div>
                  </div>
                </li>
              ) : !search && blogs?.length === 0 ? (
                <li>
                  <div className="d-flex p-2 mb-2 mb-2 w-100" style={{ cursor: 'pointer' }}>
                    <div style={{ width: '90%' }}>
                      <div
                        className="text-start"
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        No blogs available
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                <>
                  {!search
                    ? blogs?.map((blog) => (
                        <li key={blog._id} onClick={() => handleBlogDetail(blog._id)}>
                          <div className="d-flex p-2 mb-2 mb-2 w-100" style={{ cursor: 'pointer' }}>
                            <div style={{ width: '90%' }}>
                              <div
                                className="text-start"
                                title={blog.title}
                                style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                              >
                                {blog.title}
                              </div>
                            </div>
                            
                            <div style={{ width: '10%', position: 'relative' }}>
                              <Tooltip text="Blog Detail" position="left">
                                <NextArrow />
                              </Tooltip>
                            </div>
                           
                          </div>
                        </li>
                      ))
                    : searchData?.map((blog) => (
                        <li key={blog._id} onClick={() => handleBlogDetail(blog._id)}>
                          <div className="d-flex p-2 mb-2 mb-2 w-100" style={{ cursor: 'pointer' }}>
                            <div style={{ width: '90%' }}>
                              <div
                                className="text-start"
                                title={blog.title}
                                style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                              >
                                {blog.title}
                              </div>
                            </div>
                            <div style={{ width: '10%', position: 'relative' }}>
                              <NextArrow />
                            </div>
                          </div>
                        </li>
                      ))}
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
      <BottomFooter active="main" />
    </div>
  );
};

export default HomeScreen;
